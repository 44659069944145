import { createApp } from 'vue'
import App from './App.vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import './assets/css/common.scss';
import './assets/css/iconfont/iconfont.css';

// import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import { ElMessage } from 'element-plus'
import router from './router/index.js';
import tools from './request/utils';
const app = createApp(App);
Object.keys(tools).forEach((key)=>{
    app.config.globalProperties['$'+key]=tools[key];
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(router)
// app.use(ElementPlus)
app.mount('#app')
