import {createRouter, createWebHashHistory} from 'vue-router';

const router = createRouter({
    routes: [

        {
            path: '/',
            component: () => import('@/layout/index'),
            children: [{
                path: '/',
                meta: {name: '首页', path: '/',},
                component: () => import('@/views/navigation/home'),
                name: 'Home'
            },
                {
                    path: '/survey/:id',
                    component: () => import('@/views/navigation/survey'),
                    meta: {name: '工会概况', path: '/survey',},
                    name: 'survey'
                }, {
                    path: '/dynamic/:id',
                    component: () => import('@/views/navigation/dynamic'),
                    meta: {name: '工会动态', path: '/dynamic'},
                    name: 'dynamic'
                },
                {
                    path: '/building/:id',
                    component: () => import('@/views/navigation/building'),
                    meta: {name: '党建引领', path: '/building'},
                    name: 'building'
                }, {
                    path: '/join',
                    component: () => import('@/views/join/join'),
                    meta: {name: '单位入会', path: '/join'},
                    name: 'join'
                }, {
                    path: '/transfer',
                    component: () => import('@/views//join/transfer'),
                    meta: {name: '转会申请', path: '/transfer'},
                    name: 'transfer'
                },{
                    path: '/join/query',
                    component: () => import('@/views/join/query'),
                    meta: {name: '审批查询', path: '/join'},
                    name: 'joinquery'
                },{
                    path: '/join/memberApply',
                    component: () => import('@/views/join/memberApply'),
                    meta: {name: '会员申请', path: '/join'},
                    name: 'memberApply'
                },
                {
                    path: '/rights/:id',
                    component: () => import('@/views/navigation/rights'),
                    meta: {name: '我要维权', path: '/rights'},
                    name: 'rights'
                }, {
                    path: '/policy',
                    component: () => import('@/views/navigation/policy'),
                    meta: {name: '政策帮扶', path: '/policy'},
                    name: 'policy'
                },
                {
                    path: '/factory/:id',
                    component: () => import('@/views/navigation/factory'),
                    meta: {name: '产改建设', path: '/factory'},
                    name: 'factory'
                },
                {
                    path: '/ask',
                    component: () => import('@/views/navigation/ask'),
                    meta: {name: '我要咨询', path: '/ask'},
                    name: 'ask'
                },
                {
                    path: '/rights/yuyue',
                    component: () => import('@/views/navigation/yuyue'),
                    meta: {name: '维权预约', path: '/yuyue'},
                    name: 'yuyue'
                },
                {
                    path: '/new',
                    component: () => import('@/views/news/new'),
                    name: 'new'
                },
                {
                    path: '/other',
                    component: () => import('@/views/navigation/other'),
                    name: 'other'
                },
                {
                    path: '/rights/ask',
                    component: () => import('@/views/legal/ask'),
                    meta: {name: '法律咨询', path: '/rights/ask'},
                    name: 'rightsAsk'
                },


                {
                    path: '/legal/detail',
                    component: () => import('@/views/legal/detail'),
                    // meta: {name: '三师一室', path: '/legal/detail2'},
                    // name: 'legalDetail'
                }, {
                    path: '/ask/reply',
                    component: () => import('@/views/list/askList'),
                    meta: {name: '咨询列表', path: '/askList'},
                    name: 'askList'
                }, {
                    path: '/askReply',
                    component: () => import('@/views/list/askReply'),
                    meta: {name: '咨询列表', path: '/askReply'},
                    name: 'askReply'
                }, {
                    path: '/rights/reply',
                    component: () => import('@/views/list/rightList'),
                    meta: {name: '预约列表', path: '/list/rightList'},
                    name: '/rights/reply'
                }, {
                    path: '/rightReply',
                    component: () => import('@/views/list/rightReply'),
                    meta: {name: '咨询列表', path: '/list/rightReply'},
                    name: 'reply'
                }, {
                    path: '/venue',
                    component: () => import('@/views/venue/venue'),
                    meta: {name: '场馆预定', path: '/venue'},
                    name: 'venue'
                },{
                    path: '/train',
                    component: () => import('@/views/train/train'),
                    meta: {name: '课程培训', path: '/train'},
                    name: 'train'
                },{
                    path: '/train/detail',
                    component: () => import('@/views/train/detail'),
                    meta: {name: '课程详情', path: '/train/detail'},
                    name: 'traindetail'
                },{
                    path: '/venue/record',
                    component: () => import('@/views/venue/record'),
                    meta: {name: '预约记录', path: '/venue/record'},
                    name: 'venuerecord'
                },
                {
                    path: '/contribute',
                    component: () => import('@/views/navigation/contribute'),
                    meta: {name: '我要投稿', path: '/contribute'},
                    name: 'contribute'
                },
            ]
        },
        {
            path: '/info',
            component: () => import('@/views/account/info'),
            meta: {name: '完善信息', path: '/info'},
            name: 'info'
        },
        {
            path: '/register',
            component: () => import('@/views/account/register'),
            meta: {name: '注册', path: '/register',},
            name: 'register'
        }, {
            path: '/login',
            component: () => import('@/views/account/login'),
            meta: {name: '登录', path: '/login',},
            name: 'login'
        }, {
            path: '/forget',
            component: () => import('@/views/account/forget'),
            meta: {name: '忘记密码', path: '/forget',},
            name: 'forget'
        },
        // {
        //     path: '/:catchAll(.*)',
        //    redirect:"/"
        // }

    ],

    history: createWebHashHistory(),
})
export default router;


