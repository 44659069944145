import Header from '@/layout/header';
import Footer from '@/layout/footer';
import rightAside from '@/layout/rightAside';
import { ElConfigProvider } from "element-plus";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import { reactive, toRefs } from "vue";
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    rightAside,
    ElConfigProvider
  },
  setup() {
    const state = reactive({
      zhCn
    });
    return {
      ...toRefs(state)
    };
  }
};