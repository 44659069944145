//1、引入axios
import axios from 'axios'
//2、创建axios的实例
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers['token'] =  localStorage.getItem('unionToken');

let httpService = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? "/api" :'/',// TODO:具体的配置可以根据项目情况而来
    timeout: 10000
})
//3、axios的拦截--request
httpService.interceptors.request.use(config => {

    return config;
}, err => {
    Promise.reject(err);// 请求错误处理
})

//4、axios的拦截--response
httpService.interceptors.response.use(response => {
    // console.log(response.data.code)
    return response;
}, err => {
    let {code}=err.response.data
    if(code==401){
        localStorage.removeItem('unionToken');
        localStorage.removeItem('uid')
    }

    // TODO:具体的code对应的处理可继续添加修改
    return Promise.reject(err);
})

//5、get请求的封装
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        params.token= localStorage.getItem('unionToken');
        params.uid=localStorage.getItem('uid');
        httpService({
            url: url,
            method: 'get',
            params,   headers: { 'Content-Type': 'multipart/form-data' }
        }).
        then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    })
}

//6、post请求封装  默认json格式:'Content-Type':'application/json',如果是文件上传，可以修改headers为 headers: { 'Content-Type': 'multipart/form-data' }
export function post(url, params = {}, headers = {}) {
    params.token= localStorage.getItem('unionToken');
    params.uid=localStorage.getItem('uid');
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'post',
            data: params,
            headers: headers
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    })
}
