import "core-js/modules/es.array.push.js";
import { onMounted, reactive, toRefs } from "vue";
import { useRouter } from 'vue-router';
import { beian, flink, serviceInfo } from "@/request/api";
export default {
  name: "footer1",
  setup() {
    let router = useRouter();
    const state = reactive({
      nav: [{
        name: '首页',
        url: '/'
      }, {
        name: '工会概况',
        url: '/survey/17',
        child: [{
          name: '工会介绍',
          url: '/survey/17'
        }, {
          name: '主要领导',
          url: '/survey/19'
        }, {
          name: '组织架构',
          url: '/survey/18'
        }]
      }, {
        name: '工会动态',
        url: '/dynamic/20',
        child: [{
          name: '通知公告',
          url: '/dynamic/20'
        }, {
          name: '宣传教育',
          url: '/dynamic/21'
        }, {
          name: '工会要闻',
          url: '/dynamic/22'
        }]
      }, {
        name: '党建引领',
        url: '/building/11',
        child: [{
          name: '党建知识',
          url: '/building/11'
        }, {
          name: '思想宣传',
          url: '/building/7'
        }, {
          name: '党风廉政',
          url: '/building/10'
        }, {
          name: '学习园地',
          url: '/building/6'
        }, {
          name: '主题公园',
          url: '/building/9'
        }]
      }, {
        name: '我要入会',
        url: '/join'
      }, {
        name: '我要维权',
        url: '/rights/26'
      }, {
        name: '政策帮扶',
        url: '/policy'
      }, {
        name: '产改建设',
        url: '/factory'
      }, {
        name: '我要咨询',
        url: '/ask'
      }],
      info: {},
      bei: '',
      value: '国家部委网站',
      value1: '各省市政府网站',
      value2: '省内各设区市网站',
      value3: '县市区政府网站',
      value4: '【省级工会网站】',
      value5: '【市县产业工会】',
      urls: {
        country: [{
          text: '国家互联网信息办公室',
          url: 'http://www.cac.gov.cn'
        }, {
          text: '国家互联网信息办公室',
          url: 'http://www.cac.gov.cn'
        }, {
          text: '国家公务员局',
          url: 'http://www.scs.gov.cn'
        }, {
          text: '国家铁路局',
          url: 'http://www.nra.gov.cn'
        }, {
          text: '国家林业和草原局',
          url: 'http://www.forestry.gov.cn'
        }, {
          text: '国家新闻出版署（国家版权局）',
          url: 'http://www.ncac.gov.cn'
        }, {
          text: '国家机关事务管理局',
          url: 'http://www.ggj.gov.cn'
        }, {
          text: '文化和旅游部',
          url: 'https://www.mct.gov.cn'
        }, {
          text: '生态环境部',
          url: 'http://www.mee.gov.cn'
        }, {
          text: '司法部',
          url: 'http://www.moj.gov.cn'
        }, {
          text: '中央人民政府',
          url: 'http://www.gov.cn'
        }, {
          text: '外交部',
          url: 'http://www.mfa.gov.cn'
        }, {
          text: '国家发展和改革委员会',
          url: 'http://www.ndrc.gov.cn'
        }, {
          text: '商务部',
          url: 'http://www.mofcom.gov.cn'
        }, {
          text: '科学技术部',
          url: 'http://www.most.gov.cn'
        }, {
          text: '国家民族事务委员会',
          url: 'http://www.seac.gov.cn'
        }, {
          text: '公安部',
          url: 'http://www.mps.gov.cn'
        }, {
          text: '民政部',
          url: 'http://www.mca.gov.cn'
        }, {
          text: '财政部',
          url: 'http://www.mof.gov.cn'
        }, {
          text: '自然资源部',
          url: 'http://www.mnr.gov.cn'
        }, {
          text: '住房和城乡建设部',
          url: 'http://www.mohurd.gov.cn'
        }, {
          text: '交通运输部',
          url: 'http://www.mot.gov.cn'
        }, {
          text: '工业和信息化部',
          url: 'http://www.miit.gov.cn'
        }, {
          text: '农业农村部',
          url: 'http://www.moa.gov.cn'
        }, {
          text: '水利部',
          url: 'http://www.mwr.gov.cn'
        }, {
          text: '国家卫生健康委员会',
          url: 'http://www.nhc.gov.cn'
        }, {
          text: '人力资源和社会保障部',
          url: 'http://www.mohrss.gov.cn'
        }, {
          text: '中国人民银行',
          url: 'http://www.pbc.gov.cn'
        }, {
          text: '国家邮政局',
          url: 'http://www.spb.gov.cn'
        }, {
          text: '国家外汇管理局',
          url: 'http://www.safe.gov.cn'
        }, {
          text: '国家中医药管理局',
          url: 'http://www.satcm.gov.cn'
        }, {
          text: '中国民用航空局',
          url: 'http://www.caac.gov.cn/index.html'
        }, {
          text: '国家统计局',
          url: 'http://www.stats.gov.cn'
        }, {
          text: '国家税务总局',
          url: 'http://www.chinatax.gov.cn'
        }, {
          text: '国家广播电视总局',
          url: 'http://www.nrta.gov.cn'
        }, {
          text: '国家知识产权局',
          url: 'http://www.cnipa.gov.cn'
        }, {
          text: '中国气象局',
          url: 'http://www.cma.gov.cn'
        }],
        province: [{
          text: '西藏',
          url: 'http://www.xizang.gov.cn'
        }, {
          text: '新疆生产建设兵团',
          url: 'http://www.xjbt.gov.cn'
        }, {
          text: '宁夏',
          url: 'http://www.nx.gov.cn'
        }, {
          text: '北京',
          url: 'http://www.beijing.gov.cn'
        }, {
          text: '天津',
          url: 'http://www.tj.gov.cn'
        }, {
          text: '重庆',
          url: 'http://www.cq.gov.cn'
        }, {
          text: '河北',
          url: 'http://www.hebei.gov.cn'
        }, {
          text: '山西',
          url: 'http://www.shanxi.gov.cn'
        }, {
          text: '山东',
          url: 'http://www.sd.gov.cn'
        }, {
          text: '辽宁',
          url: 'http://www.ln.gov.cn'
        }, {
          text: '吉林',
          url: 'http://www.jl.gov.cn'
        }, {
          text: '江苏',
          url: 'http://www.jiangsu.gov.cn'
        }, {
          text: '黑龙江',
          url: 'http://www.hlj.gov.cn'
        }, {
          text: '安徽',
          url: 'http://www.ah.gov.cn'
        }, {
          text: '浙江',
          url: 'http://www.zj.gov.cn'
        }, {
          text: '福建',
          url: 'http://www.fj.gov.cn'
        }, {
          text: '河南',
          url: 'http://www.henan.gov.cn'
        }, {
          text: '江西',
          url: 'http://www.jiangxi.gov.cn'
        }, {
          text: '湖北',
          url: 'http://www.hubei.gov.cn'
        }, {
          text: '湖南',
          url: 'http://www.hunan.gov.cn'
        }, {
          text: '上海',
          url: 'http://www.shanghai.gov.cn'
        }, {
          text: '广东',
          url: 'http://www.gd.gov.cn'
        }, {
          text: '海南',
          url: 'http://www.hainan.gov.cn'
        }, {
          text: '四川',
          url: 'http://www.sc.gov.cn'
        }, {
          text: '贵州',
          url: 'https://www.guizhou.gov.cn'
        }, {
          text: '云南',
          url: 'http://www.yn.gov.cn'
        }, {
          text: '陕西',
          url: 'http://www.shaanxi.gov.cn'
        }, {
          text: '甘肃',
          url: 'http://www.gansu.gov.cn'
        }, {
          text: '青海',
          url: 'http://www.qinghai.gov.cn'
        }, {
          text: '内蒙古',
          url: 'http://www.nmg.gov.cn'
        }, {
          text: '台湾',
          url: 'http://www.taiwan.cn'
        }, {
          text: '广西',
          url: 'http://www.gxzf.gov.cn'
        }, {
          text: '新疆',
          url: 'http://www.xinjiang.gov.cn'
        }, {
          text: '香港',
          url: 'http://www.gov.hk'
        }, {
          text: '澳门',
          url: 'http://www.gov.mo'
        }],
        inProvince: [{
          text: '上饶市人民政府',
          url: 'http://www.zgsr.gov.cn'
        }, {
          text: '吉安市人民政府',
          url: 'http://www.jian.gov.cn'
        }, {
          text: '新余市人民政府',
          url: 'http://www.xinyu.gov.cn'
        }, {
          text: '抚州市人民政府',
          url: 'http://www.jxfz.gov.cn'
        }, {
          text: '宜春市人民政府',
          url: 'http://www.yichun.gov.cn'
        }, {
          text: '九江市人民政府',
          url: 'http://www.jiujiang.gov.cn'
        }, {
          text: '萍乡市人民政府',
          url: 'http://www.pingxiang.gov.cn'
        }, {
          text: '鹰潭市人民政府',
          url: 'http://www.yingtan.gov.cn'
        }, {
          text: '赣州市人民政府',
          url: 'https://www.ganzhou.gov.cn'
        }, {
          text: '景德镇市人民政府',
          url: 'http://www.jdz.gov.cn'
        }, {
          text: '南昌市人民政府',
          url: 'http://www.nc.gov.cn'
        }],
        inArea: [{
          text: '鄱阳湖生态科技城',
          url: 'http://pet-city.jiujiang.gov.cn'
        }, {
          text: '八里湖新区管委会',
          url: 'http://blhxq.jiujiang.gov.cn'
        }, {
          text: '柴桑区人民政府',
          url: 'http://www.chaisang.gov.cn'
        }, {
          text: '濂溪区人民政府',
          url: 'http://www.lianxi.gov.cn'
        }, {
          text: '浔阳区人民政府',
          url: 'http://www.xunyang.gov.cn'
        }, {
          text: '庐山市人民政府',
          url: 'http://www.lushan.gov.cn'
        }, {
          text: '德安县人民政府',
          url: 'http://www.dean.gov.cn'
        }, {
          text: '永修县人民政府',
          url: 'http://www.yongxiu.gov.cn'
        }, {
          text: '瑞昌市人民政府',
          url: 'http://www.ruichang.gov.cn'
        }, {
          text: '修水县人民政府',
          url: 'http://www.xiushui.gov.cn'
        }, {
          text: '武宁县人民政府',
          url: 'http://www.wuning.gov.cn'
        }, {
          text: '都昌县人民政府',
          url: 'http://www.duchang.gov.cn'
        }, {
          text: '彭泽县人民政府',
          url: 'http://www.pengze.gov.cn'
        }, {
          text: '湖口县人民政府',
          url: 'http://www.hukou.gov.cn'
        }, {
          text: '九江开发区管委会',
          url: 'http://jkq.jiujiang.gov.cn'
        }, {
          text: '共青城市人民政府',
          url: 'http://www.gongqing.gov.cn'
        }, {
          text: '庐山西海风景名胜区管委会',
          url: 'http://lsxh.jiujiang.gov.cn'
        }],
        xgh: [{
          text: '九江市总工会',
          url: 'http://www.jjgh.org.cn'
        }, {
          text: '萍乡市总工会',
          url: 'http://www.pxszgh.org.cn'
        }, {
          text: '新余市总工会',
          url: 'http://www.xyzgh.gov.cn'
        }, {
          text: '吉安市总工会',
          url: 'http://www.jagh.org.cn'
        }]
      }
    });
    const toNav = item => {
      console.log(item);
      if (item.hasOwnProperty('childlist')) {
        router.push(item.childlist[0].nickname);
      } else {
        router.push(item.nickname);
      }
    };
    const changeUrl = item => {
      let {
        value
      } = state;
      window.open(value, '_blank');
    };
    const changeUrl1 = item => {
      let {
        value1
      } = state;
      window.open(value1, '_blank');
    };
    const changeUrl2 = item => {
      let {
        value2
      } = state;
      window.open(value2, '_blank');
    };
    const changeUrl3 = item => {
      let {
        value3
      } = state;
      window.open(value3, '_blank');
    };
    const changeUrl4 = item => {
      let {
        value4
      } = state;
      window.open(value4, '_blank');
    };
    const changeUrl5 = item => {
      let {
        value5
      } = state;
      window.open(value5, '_blank');
    };
    onMounted(() => {
      state.nav = JSON.parse(localStorage.getItem('navs'));
      getService();
      getUrl();
    });
    const getService = async () => {
      let {
        data
      } = await serviceInfo();
      state.info = data;
      let {
        data: data1
      } = await beian();
      state.bei = data1.beian;
    };
    const getUrl = async () => {
      let {
        data
      } = await flink();
      state.urls = data;
    };
    return {
      ...toRefs(state),
      toNav,
      changeUrl,
      changeUrl1,
      changeUrl2,
      changeUrl3,
      changeUrl4,
      changeUrl5
    };
  }
};