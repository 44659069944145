import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "html"
};
const _hoisted_2 = {
  class: "body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_config_provider = _resolveComponent("el-config-provider");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [_createVNode(_component_el_config_provider, {
    locale: _ctx.zhCn
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view, null, {
      default: _withCtx(({
        Component
      }) => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
      _: 1
    })]),
    _: 1
  }, 8, ["locale"])])])]);
}