import "core-js/modules/es.array.push.js";
import { onMounted, reactive, toRefs, watch } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { artileDetail, categoory_list } from "@/request/api";
export default {
  components: {
    ElMessage
  },
  name: "header1",
  setup() {
    let router = useRouter();
    let route = useRoute();
    const state = reactive({
      current: '/join',
      menu: [],
      nav: [],
      uid: localStorage.getItem('uid'),
      username: localStorage.getItem('username')
    });
    watch(() => router.currentRoute.value, newValue => {}, {
      immediate: true,
      flush: 'post'
    });
    onMounted(() => {
      getCate();
    });
    const exit = () => {
      localStorage.removeItem('uid');
      location.reload();
    };
    const getCate = async () => {
      let {
        data
      } = await categoory_list();
      state.menu = data.filter(ele => {
        if (ele.childlist.length == 1) {
          delete ele.childlist;
        }
        if (ele.id == 2) {
          ele.childlist = ele.childlist.filter(sele => {
            return sele.id != 23 && sele.id != 31;
          });
        }
        return ele;
        // return ele.id != 2 && ele.id != 16
      });
      state.menu.unshift({
        nickname: '/',
        name: '首页'
      });
      state.menu[7].childlist.splice(1, 0, {
        name: '维权回复',
        nickname: '/rights/reply'
      });
      // console.log(state.menu)
      // state.menu.push({
      //     name: '我要咨询', nickname: '/ask', childlist: [
      //         {name: '我要咨询', nickname: '/ask'},
      //         {name: '咨询回复', nickname: '/ask/reply'}
      //     ]
      // })

      localStorage.setItem('navs', JSON.stringify(state.menu));
      setTimeout(() => {
        let {
          path
        } = route;
        if (path == '/new') {
          let article_id = router.currentRoute.value.fullPath.split('=')[1];
          artileDetail({
            article_id
          }).then(res => {
            let {
              category_id
            } = res.data;
            let curPath = '';
            state.menu.forEach(ele => {
              if (ele.childlist) {
                ele.childlist.forEach(s => {
                  let id = s.nickname.split('/')[2];
                  if (category_id == id) {
                    curPath = s.nickname;
                  }
                });
              }
            });
            state.current = curPath;
          });
        } else {
          state.current = path;
        }
      }, 100);
    };
    const toNav = url => {
      router.push(url);
    };
    return {
      ...toRefs(state),
      toNav,
      exit
    };
  }
};