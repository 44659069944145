import {get, post} from '@/request/index';
// method, url, data = null
//首页轮播
export function indexAdList() {
    return get('/index.php/api/index/adList',)
}

//分类内容列表
export function categoory_list(obj) {
    return get('/index.php/api/index/categoory_list', obj)
}

//获取子分类
export function childCategory(obj) {
    return get('/index.php/api/index/getChildCategory', obj)
}
//个人信息
export function get_userinfo(params) {
	return get('/index.php/api/user/get_userinfo', params)
}
//分类内容列表
export function artileList(obj) {
    return get('/index.php/api/index/artileList', obj)
}

//分类内容详情
export function artileDetail(obj) {
    console.log(obj)
    return get('/index.php/api/index/articleDetail', obj)
}

//申请入会
export function indexJoin(obj) {
    return post('/index.php/api/index/join', obj)
}

//工会动态
export function main_dongtai(obj) {
    return get('/index.php/api/index/main_dongtai', obj)
}

//党建引领
export function main_dangjian(obj) {
    return get('/index.php/api/index/main_dangjian', obj)
}

//服务咨询
export function serviceInfo(obj) {
    return get('/index.php/api/index/serviceInfo', obj)
}

//在线留言
export function note(obj) {
    return get('/index.php/api/index/note', obj)
}

//在线预约
export function yuyue(obj) {
    return get('/index.php/api/index/yuyue', obj)
}

//法律团队列表
export function team_list(obj) {
    return get('/index.php/api/index/team_list', obj)
}//法律团队详情
export function team_detail(obj) {
    return get('/index.php/api/index/team_detail', obj)
}

//地图点位分类
export function site_cate(obj) {
    return get('/index.php/api/index/site_cate', obj)
}

//地图点位列表
export function site_list(obj) {
    return get('/index.php/api/index/site_list', obj)
}

//选择类型
export function join_type(obj) {
    return get('/index.php/api/index/join_type', obj)
}

//备案号
export function beian(obj) {
    return get('/index.php/api/index/beian', obj)
}

//注册
export function userRegister(obj) {
    return post('/index.php/api/user/register', obj)
}//重置密码
export function userResetpwd(obj) {
    return post('/index.php/api/user/resetpwd', obj)
}

//登录
export function userLogin(obj) {
    return post('/index.php/api/user/login', obj)
}

//发送验证码
export function sendmsg(obj) {
    return get('/index.php/api/index/sendmsg', obj)
}

//咨询列表
export function note_list(obj) {
    return get('/index.php/api/index/note_list', obj)
}

//咨询详情
export function note_detail(obj) {
    return get('/index.php/api/index/note_detail', obj)
}

//预约列表
export function yuyue_list(obj) {
    return get('/index.php/api/index/yuyue_list', obj)
}

//预约详情
export function yuyue_detail(obj) {
    return get('/index.php/api/index/yuyue_detail', obj)
}

//课程培训分类
export function px_cate(obj) {
    return get('/index.php/api/px/px_cate', obj)
}

//课程培训列表
export function px_schools(obj) {
    return get('/index.php/api/px/px_schools', obj)
}

//课程培训详情
export function school_detail(obj) {
    return get('/index.php/api/px/school_detail', obj)
}

//课程培训报名
export function baoming(obj) {
    return post('/index.php/api/px/baoming', obj)
}

//场馆列表
export function yuyueLists(obj) {
    return get('/index.php/api/yuyue/lists', obj)
}

//场馆详情
export function yuyueDetail(obj) {
    return get('/index.php/api/yuyue/detail', obj)
}//添加预约
export function yuyueAdd(obj) {
    return get('/index.php/api/yuyue/add', obj)
}
//预约记录
export function yuyueYy_order(obj) {
    return get('/index.php/api/yuyue/yy_order', obj)
}
//友情链接
export function flink(obj) {
    return get('/index.php/api/index/flink', obj)
}//友情链接
export function indexXuzhi(obj) {
    return get('/index.php/api/index/xuzhi', obj)
}
//申请入会列表
export function join_list(obj) {
    return get('/index.php/api/index/join_list', obj)
}
//申请入会详情
export function join_detail(obj) {
    return get('/index.php/api/index/join_detail', obj)
}
//上级单位
export function top_unit(obj) {
    return get('/index.php/api/index/top_unit', obj)
}//会员申请
export function ruhui(obj) {
    return get('/index.php/api/index/ruhui', obj)
}
//完善信息
export function complete_information(obj) {
    return post('/index.php/api/user/complete_information', obj)
}
//投稿分类
export function userCategory(obj) {
    return post('/index.php/api/user/category', obj)
}
//投稿
export function tougao(obj) {
    return post('/index.php/api/user/tougao', obj)
}//转会
export function zhuanhui(obj) {
    return post('/index.php/api/index/zhuanhui', obj)
}
//预约课程记录
export function yuyueYy_kc(obj) {
    return get('/index.php/api/yuyue/yy_kc', obj)
}//预约课程评价
export function yuyuePj(obj) {
    return post('/index.php/api/yuyue/pj', obj)
}


