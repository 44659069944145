import "core-js/modules/es.array.push.js";
export default {
  name: "asides",
  data() {
    return {
      showIndex: -1,
      list: [{
        title: '入会',
        icon: require('@/assets/img/icon-kefu1.png')
      }, {
        title: '咨询',
        icon: require('@/assets/img/zixun.png')
      }, {
        title: '手机APP',
        icon: 'icon-phone'
      }, {
        title: '公众号',
        icon: 'icon-yijianfankui'
      }, {
        title: 'TOP',
        icon: require('@/assets/img/top.png')
      }],
      tel: ''
    };
  },
  methods: {
    enterPage(index) {
      if (index == 0) {
        this.$router.push({
          path: '/join'
        });
      }
      if (index == 1) {
        this.$router.push({
          path: '/ask'
        });
      }
      if (index == 4) {
        let top = document.documentElement.scrollTop || document.body.scrollTop;
        // 实现滚动效果
        const timeTop = setInterval(() => {
          document.body.scrollTop = document.documentElement.scrollTop = top -= 10;
          if (top <= 0) {
            clearInterval(timeTop);
          }
        }, 1);
      }
    }
  }
};